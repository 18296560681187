// Generated by Framer (78a4586)

import { addFonts, cx, CycleVariantState, Link, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./aKlDFqxpP-0.js";

const enabledGestures = {PWo4yoIHn: {hover: true}};

const cycleOrder = ["PWo4yoIHn"];

const serializationHash = "framer-ccBcg"

const variantClassNames = {PWo4yoIHn: "framer-v-1ouodqy"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {UqrkdNuY1: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "PWo4yoIHn", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={"https://br.linkedin.com/company/scoder-tech"} openInNewTab><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1ouodqy", className, classNames)} framer-1229qf2`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"PWo4yoIHn"} ref={ref ?? ref1} style={{opacity: 1, ...style}} variants={{"PWo4yoIHn-hover": {opacity: 0.4}}} {...addPropertyOverrides({"PWo4yoIHn-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><SVG className={"framer-uyweem"} data-framer-name={"Vector"} layout={"position"} layoutDependency={layoutDependency} layoutId={"XSnmag42N"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 20 20\"><path d=\"M 19.959 11.719 L 19.959 19.098 L 15.681 19.098 L 15.681 12.213 C 15.681 10.483 15.062 9.303 13.514 9.303 C 12.332 9.303 11.628 10.099 11.319 10.868 C 11.206 11.143 11.177 11.526 11.177 11.911 L 11.177 19.098 L 6.897 19.098 C 6.897 19.098 6.955 7.438 6.897 6.229 L 11.177 6.229 L 11.177 8.053 L 11.149 8.095 L 11.177 8.095 L 11.177 8.053 C 11.745 7.178 12.76 5.927 15.033 5.927 C 17.848 5.927 19.959 7.767 19.959 11.719 Z M 2.421 0.026 C 0.958 0.026 0 0.986 0 2.249 C 0 3.484 0.93 4.473 2.365 4.473 L 2.393 4.473 C 3.886 4.473 4.813 3.484 4.813 2.249 C 4.787 0.986 3.887 0.026 2.422 0.026 Z M 0.254 19.098 L 4.532 19.098 L 4.532 6.229 L 0.254 6.229 Z\" fill=\"rgb(255,255,255)\"></path></svg>"} svgContentId={1263126239} withExternalLayout {...addPropertyOverrides({"PWo4yoIHn-hover": {svgContentId: 9853060831}}, baseVariant, gestureVariant)}/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-ccBcg.framer-1229qf2, .framer-ccBcg .framer-1229qf2 { display: block; }", ".framer-ccBcg.framer-1ouodqy { cursor: pointer; height: 24px; overflow: hidden; position: relative; text-decoration: none; width: 24px; }", ".framer-ccBcg .framer-uyweem { flex: none; height: 20px; left: 2px; position: absolute; top: 2px; width: 20px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 24
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"tLE0lb4TJ":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FrameraKlDFqxpP: React.ComponentType<Props> = withCSS(Component, css, "framer-ccBcg") as typeof Component;
export default FrameraKlDFqxpP;

FrameraKlDFqxpP.displayName = "linkedin";

FrameraKlDFqxpP.defaultProps = {height: 24, width: 24};

addFonts(FrameraKlDFqxpP, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})